import "./product.js";
document.addEventListener("DOMContentLoaded", function () {
  const models = document.querySelectorAll(".b3dviewer-wrapper:not(.elementor)");
  Object.values(models).map((wrapper) => {
    modelRenderer({ animation: true, variant: true, wrapper });
  });
});

window.addEventListener("elementor/frontend/init", function () {
  elementorFrontend.hooks.addAction("frontend/element_ready/3dModelViewer.default", function (scope, $) {
    const wrapper = scope[0].querySelector(".b3dviewer-wrapper.elementor");
    console.log("elementor rendered");
    modelRenderer({ animation: true, variant: true, wrapper });
  });
});

const createSelector = (element, options = [], selected, show = true) => {
  if (element?.parentElement) {
    if (show) {
      element.parentElement.style.display = "inline-block";
    }
    element.innerHTML = "";
  } else {
    return;
  }
  if (!options.length && element.parentElement) {
    if (window.location.href.includes("wp-admin")) {
      options[0] = "not available";
    } else {
      element.parentElement.style.display = "none";
    }
  }
  for (const name of options) {
    const option = document.createElement("option");
    option.value = name;
    option.textContent = name;
    element.appendChild(option);
    if (name === selected) {
      option.selected = true;
    }
  }
};

function modelRenderer(params = {}) {
  const { animation = false, variant = false, wrapper } = params;
  if (!wrapper) {
    return;
  }

  // fullscreen
  const fullscreen = wrapper.querySelector("#openBtn");
  const closeBtn = wrapper.querySelector("#closeBtn");
  fullscreen &&
    fullscreen.addEventListener("click", () => {
      wrapper.classList.add("fullscreen");
    });
  closeBtn &&
    closeBtn.addEventListener("click", () => {
      wrapper.classList.remove("fullscreen");
    });

  //model viewer
  const modelViewer = wrapper.querySelector("model-viewer");
  if (!modelViewer) return false;

  if (modelViewer.src) {
    try {
      const source = new URL(modelViewer.src);
      if (source?.protocol !== window.location.protocol) {
        modelViewer.src = source.href.replace(source.protocol, window.location.protocol);
      }
    } catch (error) {
      console.warn(error.message, modelViewer.src);
    }
  }

  const selectedAnimation = modelViewer.dataset.animation;

  const select = wrapper.querySelector("#variant");
  const animations = wrapper.querySelector("#animations");
  const percentageWrapper = wrapper.querySelector(".percentageWrapper");
  const percentageElement = percentageWrapper?.querySelector(".percentage");

  if (modelViewer.reveal === "interaction" && percentageWrapper) {
    percentageWrapper.style.display = "none";
  }

  modelViewer.addEventListener("load", () => {
    createSelector(select, modelViewer.availableVariants, "", variant);
    createSelector(animations, modelViewer.availableAnimations, selectedAnimation, animation);
    modelViewer.animationName = selectedAnimation;

    setTimeout(() => {
      if (percentageWrapper) {
        percentageWrapper.style.display = "none";
      }
    }, 100);
  });

  //show percentage
  if (percentageElement) {
    modelViewer.addEventListener("progress", (event) => {
      percentageWrapper.style.display = "block";
      percentageElement.innerHTML = parseInt(event.detail.totalProgress * 100) + "%";
    });
  }

  select &&
    select.addEventListener("input", (event) => {
      modelViewer.variantName = event.target.value;
    });
  animations &&
    animations.addEventListener("input", (event) => {
      modelViewer.animationName = event.target.value;
    });

  // slides
  const slides = wrapper.querySelectorAll(".slide");
  for (const slide of slides) {
    slide.addEventListener("click", (e) => {
      e.preventDefault();
      modelViewer.src = slide?.dataset?.source;
      modelViewer.poster = slide?.dataset?.poster;
      slides.forEach((element) => {
        element.classList.remove("selected");
      });
      slide.classList.add("selected");
      if (select) select.innerHTML = "";
    });
  }

  document.querySelector(".slider")?.addEventListener("beforexrselect", (ev) => {
    ev.preventDefault();
  });
  // });
}
window.modelRenderer = modelRenderer;
export default modelRenderer;
