function hexToRGB(hex, type = "string") {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex.replace("#", ""));
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4], 16) || 255,
      }
    : null;

  return Object.values(rgba).filter((item) => item);
}

export default hexToRGB;
