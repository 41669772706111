import "./style.scss";
// import "./index.js";

window.addEventListener("elementor/frontend/init", function () {
  elementorFrontend.hooks.addAction("frontend/element_ready/3dModelViewer.default", function (scope, $) {
    const wrapper = scope[0].querySelector(".b3dviewer-wrapper.elementor");
    modelRenderer({ animation: true, variant: true, wrapper });
  });
});

// Full Screen
addEventListener("DOMContentLoaded", function () {
  const modelWrapper = document.querySelectorAll(".product-modal-wrap .bp_model_parent");
  Object.values(modelWrapper).map((wrapper) => {
    let modelViewer = true;
    const fullscreen = wrapper.querySelector("#openBtn");
    const closeBtn = wrapper.querySelector("#closeBtn");
    const models = wrapper.querySelectorAll("model-viewer") || wrapper.querySelectorAll(".online_3d_viewer");
    const progress = wrapper.querySelector(".progress");

    models.forEach((model) => {
      const src = new URL(model?.src || model?.getAttribute("src") || model?.getAttribute("model"));
      if (model.tagName === "DIV") {
        setTimeout(() => {
          fullscreen.addEventListener("click", () => {
            model.parentNode.classList.add("fullscreen");
            window.dispatchEvent(new Event("resize"));
          });
          closeBtn.onclick = () => {
            model.parentNode.classList.remove("fullscreen");
            window.dispatchEvent(new Event("resize"));
          };
        }, 2000);
      } else {
        // fullscreen.onclick =
        fullscreen?.addEventListener("click", () => {
          const newItem = wrapper.cloneNode(true);
          newItem.classList.add("fullscreen");
          const closeBtn = newItem.querySelector("#closeBtn");
          document.querySelector("body").appendChild(newItem);

          closeBtn?.addEventListener("click", () => {
            document.querySelector("body").removeChild(newItem);
          });
        });

        // add/remove ssl from url depends on website
        if (src?.protocol !== location?.protocol) {
          model?.setAttribute("src", src.href.replace(src?.protocol, location?.protocol));
        }

        if (progress) {
          model?.addEventListener("progress", (e) => {
            progress.innerHTML = parseInt(e.detail.totalProgress * 100) + "%";
          });
          model?.addEventListener("load", () => {
            setTimeout(() => {
              progress.style.display = "none";
            }, 500);
          });
        }

        /**
         * Woocommerce
         */

        // handle woocommerce variant
        const modelItems = jsonParse(model.dataset.items);
        const variantElement = document.querySelector(".variations_form .variations select");

        // remove data from dataset
        model.dataset.items = "";

        variantElement?.addEventListener("change", function (e) {
          const modelItem = modelItems.find((item) => item.product_variant === e.target.value) || modelItems.find((item) => item.product_variant === "all") || modelItems?.[0];
          // model.setAttribute("src", modelItem.model_src);
          model.setAttribute("src", modelItem.model_src.replace(/https?:/, location?.protocol));
        });

        document.querySelectorAll(".variable-items-wrapper li")?.forEach((variant) =>
          variant.addEventListener("click", function () {
            const modelItem = modelItems.find((item) => item.product_variant === variant.dataset?.value) || modelItems.find((item) => item.product_variant === "all") || modelItems?.[0];
            // model.setAttribute("src", modelItem.model_src);
            model.setAttribute("src", modelItem.model_src.replace(modelItem.model_src?.protocol, location?.protocol));
          })
        );

        // set model width for goya theme
        const productModalContainer = document.querySelector(".product-modal-wrap");
        if (productModalContainer && !productModalContainer?.nextElementSibling?.classList?.contains("entry-summary")) {
          productModalContainer.style.width = "100%";
          const information = document.querySelector(".showcase-inner .product-information");
          if (information) {
            const model = productModalContainer.querySelector("model-viewer");
            if (model) {
              model.style.height = information.offsetHeight + "px";
            }
          }
        }
      }
    });
  });

  let carouselData = document.querySelectorAll(".bp3dmodel-carousel");

  Object.values(carouselData).map((itemData) => {
    if (itemData.dataset.fullscreen == 1) {
      createFullScreenFeature();
    }
  });
});

jQuery(document).ready(function ($) {
  $(".bp3dmodel-carousel")?.[0]?.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  });
});

function createFullScreenFeature() {
  const bigModel = document.querySelectorAll(".bp_model_gallery");

  const hideElement = document.createElement("div");
  Object.values(bigModel).map((bigModel) => {
    const item = bigModel.querySelector("model-viewer");

    const buttonWrapper = document.createElement("div");
    buttonWrapper.classList.add("bp3d-model-buttons");
    buttonWrapper.innerHTML =
      '<svg id="openBtn" width="24px" height="24px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#f2f2f2" class="bi bi-arrows-fullscreen"> <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/> </svg> <svg id="closeBtn" width="34px" height="34px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path fill="none" stroke="#f2f2f2" stroke-width="2" d="M7,7 L17,17 M7,17 L17,7"/> </svg>';

    item.parentNode.appendChild(buttonWrapper);

    const newItem = item.parentNode.cloneNode(true);
    newItem.style.display = "none";
    newItem.onclick = function (e) {
      e.preventDefault();
    };
    const model = newItem.querySelector("model-viewer");
    model.style.width = "100%";
    model.style.height = "100%";
    model.style.maxHeight = "100%";

    hideElement.appendChild(newItem);

    const fullscreen = buttonWrapper.querySelector("#openBtn");
    const closefullscreen = newItem.querySelector("#closeBtn");
    fullscreen.onclick = () => {
      newItem.classList.add("fullscreen");
      newItem.style.display = "block";
    };
    closefullscreen.onclick = (e) => {
      e.preventDefault();
      newItem.classList.remove("fullscreen");
      newItem.style.display = "none";
    };
  });

  const body = document.querySelector("body");
  body.appendChild(hideElement);
}

function calculateRatio(num_1, num_2) {
  for (let num = num_2; num > 1; num--) {
    if (num_1 % num == 0 && num_2 % num == 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  var ratio = num_1 + ":" + num_2;
  return ratio;
}

function jsonParse(json) {
  try {
    return JSON.parse(json);
  } catch (error) {
    console.warn(error.message);
  }
  return json;
}

const createURLInstance = (src) => {
  try {
    return new URL(src);
  } catch (error) {
    return error.message;
  }
};
