import { render } from "react-dom";
import { jsonParse } from "../../../../wp-utils/v1";
import Viewer from "./Components/Viewer";

import "./../../public/style.scss";

const FrontEnd = ({ attributes }) => {
  function __(text, textdomain = "") {
    return text;
  }
  if (attributes) {
    return <Viewer attributes={attributes} __={__} />;
  }
};

export default FrontEnd;

document.addEventListener("DOMContentLoaded", function () {
  const blocks = document.querySelectorAll(".modelViewerBlock");
  if (window.domRendered) {
    return;
  }
  window.domRendered = true;
  blocks.forEach((dom) => {
    const dataset = { ...dom.dataset } || {};
    setTimeout(() => {
      Object.keys(dom.dataset).map((key) => delete dom.dataset[key]);
    }, 10);

    const attributes = jsonParse(dataset.attributes);
    console.log(attributes);
    if (attributes) {
      const selector = dataset.selector;
      if (selector && document.querySelector(selector)) {
        dom = document.querySelector(selector);
        dom.setAttribute("style", `width:${dom.offsetWidth}px !important;height:350px`);
      }
      if (!attributes?.currentViewer || attributes.currentViewer === "modelViewer") {
        dom.setAttribute("data-rendered", "true");
        render(<FrontEnd attributes={attributes} />, dom);
        const Src = document.getElementById("bp3d-model-viewer-js");
        if (!Src) {
          const script = document.createElement("script");
          script.type = "module";
          script.id = "bp3d-model-viewer-js";
          script.src = window["bp3dBlock"]?.modelViewerSrc;

          document.head.appendChild(script);
        }
      }
      if (attributes.currentViewer === "O3DViewer") {
        const Src = document.getElementById("bp3d-o3dviewer-js");
        if (!Src) {
          const script = document.createElement("script");
          script.id = "bp3d-o3dviewer-js";
          script.src = window["bp3dBlock"]?.o3dviewerSrc;
          document.head.appendChild(script);
          script.addEventListener("load", () => {
            render(<FrontEnd attributes={attributes} />, dom);
          });
        } else {
          Src.addEventListener("load", () => {
            render(<FrontEnd attributes={attributes} />, dom);
          });
        }
      }
    }
  });
});
