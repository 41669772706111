import { useEffect, useState, useRef } from "react";

import Style from "./Style";
import FullscreenOpen from "../../../components/Icons/fullscreenOpen";
import Close from "../../../components/Icons/Close";
import modelRenderer from "../../../public";
import SliderController from "./SliderControllder";
import Basic3DViewer from "../Basic3DViewer";

const Viewer = ({ attributes, __, setAttributes }) => {
  const [isValid, setIsValid] = useState(false);
  const { loadingPercentage = false, uniqueId, model, models, fullscreen, variant, multiple, additional, exposure, selectedAnimation, animation, autoRotate, autoplay, rotateAlongX, align, woo, rotationPerSecond, preload, isPagination, isNavigation, zoom, currentViewer = "modelViewer", O3DVSettings = {} } = attributes;

  const { isFullscreen } = O3DVSettings;

  const [currentItem, setCurrentItem] = useState(0);
  const [modelSrc, setModelSrc] = useState(multiple ? models[currentItem]?.modelUrl : model?.modelUrl);

  const viewerRef = useRef();
  const variantRef = useRef();
  const animationRef = useRef();

  useEffect(() => {
    setModelSrc(multiple ? models[currentItem]?.modelUrl : model?.modelUrl);
  }, [currentItem, multiple, models, model]);

  useEffect(() => {
    try {
      new URL(modelSrc);
      setIsValid(true);
    } catch (error) {
      setIsValid(false);
    }

    setTimeout(() => {
      modelRenderer({ animation, variant, wrapper: viewerRef.current?.parentElement });
    }, 10);
  }, [uniqueId, model, models, multiple, modelSrc]);

  useEffect(() => {
    if (!autoplay) {
      viewerRef.current?.pause();
    }
  }, [autoplay]);

  useEffect(() => {
    setTimeout(() => {
      if (viewerRef.current && animationRef.current) {
        viewerRef.current.animationName = selectedAnimation;
        const selectElement = animationRef.current.querySelector("select");
        selectElement.value = selectedAnimation;
      }
    }, 10);
  }, [selectedAnimation]);

  useEffect(() => {
    if (variantRef.current) {
      if (variant) {
        variantRef.current.style.display = "inline-block";
      } else {
        variantRef.current.style.display = "none";
      }
    }
  }, [variant]);

  useEffect(() => {
    if (animationRef.current) {
      if (animation && viewerRef.current.loaded) {
        animationRef.current.style.display = "inline-block";
      } else {
        animationRef.current.style.display = "none";
      }
    }
  }, [animation, animationRef]);

  useEffect(() => {
    if (viewerRef.current?.turntableRotation) {
      viewerRef.current.resetTurntableRotation();
    }
  }, [rotateAlongX]);

  useEffect(() => {
    setTimeout(() => {
      if (viewerRef.current) {
        const { mouseControl, autoplay, shadow, rotate, rotateAlongX, rotateAlongY } = attributes;
        mouseControl ? viewerRef.current.setAttribute("camera-controls", "") : viewerRef.current.removeAttribute("camera-controls");
        autoplay ? viewerRef.current.setAttribute("autoplay", "") : viewerRef.current.removeAttribute("autoplay");
        shadow ? viewerRef.current.setAttribute("shadow-intensity", "1") : viewerRef.current.removeAttribute("shadow-intensity");
        shadow ? viewerRef.current.setAttribute("shadow-softness", "1") : viewerRef.current.removeAttribute("shadow-softness");
        if (autoRotate) {
          viewerRef.current.setAttribute("auto-rotate", "");
          viewerRef.current.setAttribute("rotation-per-second", rotationPerSecond + "deg");
        } else {
          viewerRef.current.removeAttribute("auto-rotate");
        }
        rotate ? viewerRef.current.setAttribute("camera-orbit", `${rotateAlongX}deg ${rotateAlongY}deg 105%`) : viewerRef.current.removeAttribute("camera-orbit");
        viewerRef.current.setAttribute("reveal", preload);
        viewerRef.current.dataset.animation = selectedAnimation;
        viewerRef.current.setAttribute("exposure", exposure);

        if (!zoom) {
          viewerRef.current.setAttribute("disable-zoom", "");
        } else {
          viewerRef.current.removeAttribute("disable-zoom");
        }
      }
    }, 0);
  }, [uniqueId, attributes]);

  useEffect(() => {
    if (woo) {
      const variantElement = document.querySelector(".variations_form .variations select");
      variantElement?.addEventListener("change", function (e) {
        const modelItem = models.find((item) => item.product_variant === e.target.value) || models.find((item) => item.product_variant === "all") || models?.[0];
        setModelSrc(modelItem.modelUrl);
      });
    }
  }, [woo]);

  const modelPoster = multiple ? models[0]?.poster : model?.poster;

  return (
    <div id={`${uniqueId}`} className={`modelViewerBlock b3dviewer align${align} ${woo ? "woocommerce" : ""} ${isPagination && multiple ? "pagination" : ""}`}>
      <div id={additional?.ID} className={`${additional?.Class} b3dviewer-wrapper bp_model_parent `}>
        <Style attributes={attributes} />

        {modelSrc && isValid && (
          <>
            {/* {["glb", "gltf"].includes(modelSrc?.split(".")?.pop()) ? ( */}
            {currentViewer === "modelViewer" ? (
              <model-viewer camera-controls ref={viewerRef} data-js-focus-visible data-decoder={multiple ? models[0]?.decoder : model?.decoder} poster={modelPoster?.replace(/https?:/, window.location.protocol)} src={modelSrc?.replace(/https?:/, window.location.protocol)} alt="A 3D model">
                <button type="button" slot="poster" id="default-poster" aria-label="A 3D model" style={{ backgroundImage: ` url("${modelPoster?.replace(/https?:/, window.location.protocol)}")` }}></button>

                {loadingPercentage && viewerRef.current && !viewerRef.current?.loaded && (
                  <div className="percentageWrapper">
                    <div className="overlay"></div>
                    <span className="percentage">0%</span>
                  </div>
                )}
                <div className="variantWrapper select" ref={variantRef}>
                  {__("Variant", "model-viewer")} <select id="variant"></select>
                </div>
                <div className="animationWrapper select" ref={animationRef}>
                  {__("Animations", "model-viewer")} <select id="animations"></select>
                </div>
                {multiple && !woo && isPagination && (
                  <div className="slider">
                    <div className="slides">
                      {models.map((model, index) => (
                        <button key={index} className={`slide ${index === 0 ? "selected" : ""} ${model.poster ? "" : "slide-number"}`} data-source={model?.modelUrl} data-poster={model?.poster} onClick={() => setCurrentItem(index)}>
                          {model.poster ? <img src={model?.poster} /> : <span>{index + 1}</span>}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {multiple && isNavigation && models.length > 1 && <SliderController currentItem={currentItem} setCurrentItem={setCurrentItem} models={models} />}
              </model-viewer>
            ) : (
              <>
                {/* {__("This format does not support this plugin", "model-viewer")} */}
                <Basic3DViewer __={__} setAttributes={setAttributes} modelSrc={modelSrc} currentItem={currentItem} setCurrentItem={setCurrentItem} model={modelSrc} attributes={attributes} />
              </>
            )}
          </>
        )}

        {modelSrc === "" && models[currentItem].poster && (
          <>
            {woo && models.length > 1 && <SliderController currentItem={currentItem} setCurrentItem={setCurrentItem} models={models} />}
            <img src={models[currentItem].poster} />
          </>
        )}
        {/* Thumbnails for woocommerce product */}
        {((fullscreen && currentViewer === "modelViewer") || (currentViewer === "O3DViewer" && isFullscreen)) && (
          <>
            <FullscreenOpen element={document.querySelector(`#${uniqueId} .bp_model_parent`)} />
            <Close />
          </>
        )}
      </div>
      {woo && isPagination && (
        <div className="modelThumbs">
          {models.map((item, index) => {
            return <>{item.poster && <img className={`thumbsItem ${index === currentItem ? "selected" : ""}`} onClick={() => setCurrentItem(index)} src={item.poster} />}</>;
          })}
        </div>
      )}
    </div>
  );
};

export default Viewer;
