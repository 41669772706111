import React from "react";

const ModelPagination = ({ attributes, currentItem, setCurrentItem, isPagination }) => {
  const { multiple, woo, models } = attributes;
  return (
    <div>
      {multiple && !woo && isPagination && (
        <div className="slider">
          <div className="slides">
            {models.map((model, index) => (
              <button key={index} className={`slide ${index === currentItem ? "selected" : ""} ${model.poster ? "" : "slide-number"}`} data-source={model?.modelUrl} data-poster={model?.poster} onClick={() => setCurrentItem(index)}>
                {model.poster ? <img src={model?.poster} /> : <span>{index + 1}</span>}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelPagination;
